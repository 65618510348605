import {
  Row,
  Col,
  Input,
  notification,
  Segmented,
  Select,
  Typography,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';

import { Button, Editor } from '@seaters-app/ui';
import Form from 'antd/es/form';
import { Footer, ImageCropper, StyledText } from '@seaters-app/ui-shared';
import { useCallback, useEffect, useState } from 'react';
import {
  fetchTimeZone,
  useCurrencies,
  useFetchLanguages,
  useFetchTimeZones,
} from '@seaters-app/data-access';
import {
  Language,
  SEATERS_APP_BASE_URL,
  VenueFormValidatorSchema,
  VenueStatus,
  currencyMap,
  googleMapsApiKey,
  venuesKeys,
} from '@seaters-app/constants';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { VenueFormType } from '../helpers/getInitialValues';
import { useParams, useNavigate } from 'react-router-dom';
import { useIsMutating } from '@tanstack/react-query';
import { GooglePlacesSearch } from '../../../shared/GooglePlacesSearch';
import { useTranslation } from 'react-i18next';
import {
  getDetails,
  getGeocode,
  getLatLng,
  SetValue,
} from 'use-places-autocomplete';
import { simplifySearchResult } from '../../../shared/simplifySearchResults';
import { getErrorMessages } from 'apps/seaters/src/utils/helpers/getFormFieldsErrorMessages/getErrorMessages';
const { Text } = Typography;

export type FormTranslationType = {
  [lang: string]: string;
};

interface IVenueFormProps {
  initialValues: VenueFormType;
  saveChanges: (values: VenueFormType) => void;
  isDetail?: boolean;
}

const statusOptions = Object.keys(VenueStatus).map((statusItem) => ({
  label: statusItem.toLowerCase(),
  value: statusItem,
}));

export function VenueFormAsAdmin({
  initialValues,
  saveChanges,
  isDetail = false,
}: IVenueFormProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey,
    libraries: ['places'],
  });
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSubmitting = !!useIsMutating(venuesKeys.mutation());

  const [editorShown, setEditorShown] = useState(false);

  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.EN);

  const { data: languagesData } = useFetchLanguages({
    itemOffset: 0,
    maxPageSize: 100,
  });

  const { timeZonesOptions } = useFetchTimeZones();

  const { currenciesOptions: currencies } = useCurrencies();

  const currencyOptions = currencies?.map((currency) => ({
    label: `${currency.id} (${currency.name})`,
    value: currency.id,
  }));

  // const { barCodesOptions } = useBarCodes();

  const languages = languagesData
    ? languagesData?.items.map((language) => ({
        label: language.locale.toUpperCase(),
        value: language.locale,
      }))
    : [];

  const methods = useForm<VenueFormType>({
    mode: 'onBlur',
    resolver: zodResolver(VenueFormValidatorSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors, isDirty },
    trigger,
  } = methods;

  const fieldErrors = getErrorMessages<VenueFormType>(
    errors,
    [
      'name',
      'shortName',
      'slug',
      'addressLine',
      'country',
      'imageUrl',
      'city',
      'currencyCode',
      'conditions',
    ],
    currentLanguage
  );

  const longitude = useWatch({
    control,
    name: 'longitude',
  });

  const latitude = useWatch({
    control,
    name: 'latitude',
  });

  const currencyCode = useWatch({
    control,
    name: 'currencyCode',
  });

  const imageUrl = useWatch({
    control,
    name: 'imageUrl',
  });

  useEffect(() => {
    async function fetchTimeZoneData() {
      const timeZone = await fetchTimeZone(latitude, longitude);

      if (timeZone) {
        const currency =
          currencyMap[timeZone.countryCode as keyof typeof currencyMap];

        console.log('timeZone', timeZone);
        if (currency && currency !== currencyCode) {
          setValue('currencyCode', currency);

          trigger('currencyCode');
        }
      }
    }
    fetchTimeZoneData();
  }, [latitude, longitude]);

  const { venueId = '' } = useParams();

  const copyID = useCallback(() => {
    if (venueId) {
      navigator.clipboard.writeText(venueId).then(
        () => {
          notification.success({
            message: t('notification_success_id_copied', { userId: venueId }),
          });
        },
        () => {
          notification.error({
            message: t('notification_error_id_copied', { userId: venueId }),
          });
        }
      );
    }
  }, []);

  const onSubmit = (values: VenueFormType) => {
    saveChanges(values);
  };

  const handleSelectPlace = async (
    name: string,
    id: string,
    setValueForPlace: SetValue
  ) => {
    const details = await getDetails({
      placeId: id,
    });
    const simplifiedPlace = simplifySearchResult(details);
    setValueForPlace(name, false);
    const res = await getGeocode({ address: name });
    const coordinates = await getLatLng(res[0]);
    const timeZone = await fetchTimeZone(
      coordinates.lat.toString(),
      coordinates.lng.toString()
    );

    if (timeZone) {
      setValue('timeZone', timeZone.zoneName);
    }
    setValue('latitude', coordinates.lat);
    setValue('longitude', coordinates.lng);
    languages.forEach(({ value }) =>
      setValue(`city.${value}`, simplifiedPlace?.formatted_address ?? '')
    );

    if (typeof details !== 'string') {
      languages.forEach(({ value }) =>
        setValue(`country.${value}`, simplifiedPlace?.country ?? '')
      );
    }

    trigger([`country.${currentLanguage}`, `city.${currentLanguage}`]);
  };

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      autoComplete="off"
      disabled={isSubmitting}
    >
      <Form.Item
        label={<Text strong>{t('venue_image_label')}</Text>}
        name="imageUrl"
        required
        help={fieldErrors?.imageUrl}
        validateStatus={errors.imageUrl && 'error'}
      >
        <ImageCropper
          imageOrCanvas={imageUrl}
          handleRemoveImage={() => setValue('imageUrl', '')}
          handleImageChange={(img) => setValue('imageUrl', img)}
          handleReset={() => {
            setValue('imageUrl', initialValues.imageUrl);
          }}
          formItemProps={{
            label: <Text strong>{t('venue_image_label')}</Text>,
            name: '',
            validateStatus: errors.imageUrl && 'error',
          }}
        />
      </Form.Item>

      {venueId && (
        <Row gutter={24}>
          <Col xs={24} md={16} lg={12} xl={8} xxl={6}>
            <Form.Item label={<Text strong>{t('admin_id')}</Text>}>
              <Input disabled value={venueId} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} lg={8} xl={8} xxl={4}>
            <Form.Item label="hidden">
              <Button
                type="text"
                onClick={copyID}
                icon={<CopyOutlined rev={undefined} />}
                disabled={!venueId}
              >
                {t('copy_button_text')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24}>
        <Col xs={24} md={16} xl={8}>
          <Form.Item
            label={<Text strong>{t('venue_details_name')}</Text>}
            name={`name.${currentLanguage}`}
            validateStatus={errors.name && 'error'}
            help={fieldErrors?.name}
            required={currentLanguage === Language.EN}
          >
            <Controller
              control={control}
              name={`name.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={16} xl={4}>
          <Form.Item
            label={<Text strong>{t('venue_details_short-name')}</Text>}
            name={`shortName.${currentLanguage}`}
            validateStatus={errors.shortName && 'error'}
            help={fieldErrors?.shortName}
            required={currentLanguage === Language.EN}
          >
            <Controller
              control={control}
              name={`shortName.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={16} xl={12}>
          <Form.Item
            label={<Text strong>{t('admin_event_slug')}</Text>}
            name={'slug'}
            validateStatus={errors.slug && 'error'}
            help={!!errors?.slug}
            required
          >
            <Controller
              control={control}
              name={'slug'}
              render={({ field }) => (
                <Input addonBefore={SEATERS_APP_BASE_URL} {...field} />
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      {((initialValues?.latitude !== undefined &&
        initialValues?.longitude !== undefined) ||
        !isDetail) && (
        <>
          {isLoaded && (
            <Row gutter={24}>
              <Col xs={24} md={16} xl={8}>
                <Form.Item
                  name="addressLine"
                  validateStatus={errors.addressLine && 'error'}
                  help={!!errors.addressLine}
                  required
                >
                  <Controller
                    control={control}
                    name="addressLine"
                    render={() => (
                      <GooglePlacesSearch
                        handleSelect={handleSelectPlace}
                        placeholder={t('google_search_placeholder')}
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24}>
              {isLoaded && (
                <GoogleMap
                  zoom={10}
                  center={{
                    lat: Number(latitude ?? initialValues?.latitude ?? 50.8476),
                    lng: Number(
                      longitude ?? initialValues?.longitude ?? 4.3572
                    ),
                  }}
                  mapContainerStyle={{
                    width: '100%',
                    height: '400px',
                    marginBottom: 16,
                  }}
                >
                  {latitude &&
                    longitude && ( // no Market by default in create flow
                      <MarkerF
                        position={{
                          lat: Number(latitude),
                          lng: Number(longitude),
                        }}
                      />
                    )}
                </GoogleMap>
              )}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} md={12} xl={4}>
              <Form.Item
                label={<Text strong>{t('latitude_label_text')}</Text>}
                name={'latitude'}
              >
                <Controller
                  control={control}
                  name={'latitude'}
                  render={({ field }) => (
                    <Input disabled type="number" {...field} />
                  )}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} xl={4}>
              <Form.Item
                label={<Text strong>{t('longitude_label_text')}</Text>}
                name={'longitude'}
              >
                <Controller
                  control={control}
                  name={'longitude'}
                  render={({ field }) => (
                    <Input disabled type="number" {...field} />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <Row gutter={24}>
        <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('venue_details_country')}</Text>}
            name={`country.${currentLanguage}`}
            validateStatus={errors.country && 'error'}
            help={fieldErrors?.country}
            required={currentLanguage === Language.EN}
          >
            <Controller
              control={control}
              name={`country.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('event_reqinfo_address')}</Text>}
            name={`city.${currentLanguage}`}
            validateStatus={errors.city && 'error'}
            help={fieldErrors?.city}
            required={currentLanguage === Language.EN}
          >
            <Controller
              control={control}
              name={`city.${currentLanguage}`}
              render={({ field }) => <Input {...field} />}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('venue_details_currency')}</Text>}
            name="currencyCode"
            validateStatus={errors.currencyCode && 'error'}
            help={!!errors.currencyCode}
          >
            <Controller
              control={control}
              name={`currencyCode`}
              render={({ field }) => {
                return (
                  <Select
                    options={currencyOptions}
                    {...field}
                    disabled={false}
                  />
                );
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('venue_details_time-zone')}</Text>}
            name={'timeZone'}
          >
            <Controller
              control={control}
              name={'timeZone'}
              render={({ field }) => (
                <Select
                  {...field}
                  showSearch
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) =>
                    option?.value.toLowerCase().includes(input.toLowerCase()) ??
                    false
                  }
                  onSelect={(item) => {
                    setValue('timeZone', item);
                  }}
                  options={timeZonesOptions?.map(
                    (item: { name: string; id: string }, index: number) => ({
                      label: item.name,
                      value: item.id,
                    })
                  )}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        {/* <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('venue_barcode_type_label_text')}</Text>}
            name="barcodeType"
            validateStatus={errors.barcodeType && 'error'}
            help={!!errors.barcodeType}
          >
            <Controller
              control={control}
              name="barcodeType"
              render={({ field }) => (
                <Select {...field} options={barCodesOptions} />
              )}
            />
          </Form.Item>
        </Col> */}
        <Col xs={24} md={12} xl={8}>
          <Form.Item
            label={<Text strong>{t('comm_list_status')}</Text>}
            name={'status'}
          >
            <Controller
              control={control}
              name={'status'}
              render={({ field }) => (
                <Select {...field} options={statusOptions} />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} md={24} xl={16}>
          <Form.Item
            label={<Text strong>{t('admin_fan-groups_description')}</Text>}
            name={`conditions.${currentLanguage}`}
            validateStatus={errors.conditions && 'error'}
            help={fieldErrors?.conditions}
            required={currentLanguage === Language.EN}
          >
            <Controller
              control={control}
              name={`conditions.${currentLanguage}`}
              render={({ field: { onChange, ...field } }) => {
                return !editorShown ? (
                  <StyledText
                    isError={!!errors.conditions}
                    text={field.value ?? ''}
                    onClick={() => setEditorShown(true)}
                  />
                ) : (
                  <Editor {...field} onEditorChange={onChange} />
                );
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Footer
        onCancel={isDetail ? () => reset(initialValues) : () => navigate('..')}
        isDisabled={!isDirty && isDetail && imageUrl === initialValues.imageUrl}
        isLoading={isSubmitting}
      >
        <Segmented
          size="large"
          options={languages}
          value={currentLanguage}
          onChange={setCurrentLanguage}
        />
      </Footer>
    </Form>
  );
}
