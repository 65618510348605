import { all } from 'axios';
import {
  CodesQuery,
  EventsQuery,
  FanGroupsQuery,
  LanguagesQuery,
  PaginationQueryV2,
  PaginationQuery,
  TimeZonesQuery,
  UsersQuery,
  VenuesQuery,
  GroupGuestsQuery,
  WaitingListQuery,
  SurveyQuery,
  SearchEventRequestBody,
  PimCheckTransactionQuery,
  WaitingListWithApprovalQuery,
  WaitingListFiltersQuery,
  BadgesQuery,
  SortedPaginationQueryV2,
  SurveyPaginationQuery,
  FansAnswersQuery,
  FGOWishListsValidator,
  EnhancedCodesQuery,
  MailcampaignQuery,
} from './validators';

export const usersKeys = {
  all: () => ['users'] as const,
  lists: () => [...usersKeys.all(), 'list'] as const,
  list: (params: UsersQuery) => [...usersKeys.lists(), params] as const,
  host: () => [...usersKeys.all(), 'host'] as const,
  look: () => [...usersKeys.all(), 'look'] as const,
  fanGroupBySlug: (slug: string) =>
    [...usersKeys.all(), 'fanGroupBySlug', slug] as const,
  fanGroups: () => [...usersKeys.all(), 'fanGroups'] as const,
  details: () => [...usersKeys.all(), 'detail'] as const,
  detail: (id: string) => [...usersKeys.details(), id] as const,
  mutation: () => [...usersKeys.all(), 'mutation'] as const,
};

export const ownershipsKeys = {
  all: () => ['ownerships'] as const,
  lists: () => [...ownershipsKeys.all(), 'list'] as const,
  list: (params: UsersQuery) => [...ownershipsKeys.lists(), params] as const,
  forUser: (userId: string, params: UsersQuery) =>
    [...ownershipsKeys.list(params), userId] as const,
  mutation: () => [...ownershipsKeys.all(), 'mutation'] as const,
};

export const userHostRolesKeys = {
  all: () => ['ownerships'] as const,
  lists: () => [...userHostRolesKeys.all(), 'list'] as const,
  list: (params: PaginationQueryV2) =>
    [...userHostRolesKeys.lists(), params] as const,
  forUser: (userId: string, params: PaginationQueryV2) =>
    [...userHostRolesKeys.list(params), userId] as const,
  mutation: () => [...userHostRolesKeys.all(), 'mutation'] as const,
};

export const profileKeys = {
  all: () => ['profile'] as const,
  admin: () => [...profileKeys.all(), 'admin'] as const,
  fan: () => [...profileKeys.all(), 'fan'] as const,
  mutation: () => [...profileKeys.all(), 'mutation'] as const,
  roles: () => [...profileKeys.all(), 'roles'] as const,
  fanGroupRoles: (fanGroupId: string) =>
    [...profileKeys.all(), 'fanGroupRoles', fanGroupId] as const,
};

export const approverKeys = {
  all: () => ['approver'] as const,
  fanGroups: () => [...approverKeys.all(), 'fanGroups'] as const,
};

export const fanGroupsKeys = {
  all: () => ['fanGroups'] as const,
  lists: () => [...fanGroupsKeys.all(), 'list'] as const,
  joinedLists: () => [...fanGroupsKeys.all(), 'joinedList'] as const,
  joinedList: (params: FanGroupsQuery) =>
    [...fanGroupsKeys.joinedLists(), params] as const,
  list: (params: FanGroupsQuery) => [...fanGroupsKeys.lists(), params] as const,
  approver: () => [...fanGroupsKeys.all(), 'approver'] as const,
  host: () => [...fanGroupsKeys.all(), 'host'] as const,
  fanGroupOwner: () => [...fanGroupsKeys.all(), 'fanGroupOwner'] as const,
  details: () => [...fanGroupsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...fanGroupsKeys.details(), id] as const,
  codes: (id: string) => [...fanGroupsKeys.details(), id, 'codes'] as const,
  enhancedCodes: (id: string) =>
    [...fanGroupsKeys.details(), id, 'enhancedCodes'] as const,
  accessRequests: (id: string, params: PaginationQuery) =>
    [...fanGroupsKeys.details(), id, 'accessRequests', params] as const,
  codesBadgesList: (id: string) =>
    [...fanGroupsKeys.details(), id, 'codesBadges'] as const,
  codesCustomInfoList: (id: string) =>
    [...fanGroupsKeys.details(), id, 'codesCustomInfo'] as const,
  badges: (id: string) => [...fanGroupsKeys.details(), id, 'badges'] as const,
  seats: (id: string) =>
    [...fanGroupsKeys.details(), id, 'seatsNumber'] as const,
  codesLists: (id: string) => [...fanGroupsKeys.codes(id), 'list'] as const,
  codesList: (id: string, params: CodesQuery) =>
    [...fanGroupsKeys.codesLists(id), params] as const,
  enhancedCodesLists: (id: string) =>
    [...fanGroupsKeys.enhancedCodes(id), 'list'] as const,
  enhancedCodesList: (id: string, params: EnhancedCodesQuery) =>
    [...fanGroupsKeys.enhancedCodes(id), params] as const,
  mutation: () => [...fanGroupsKeys.all(), 'mutation'] as const,
  members: (id: string) => [...fanGroupsKeys.details(), id, 'members'] as const,
  membersLists: (id: string) => [...fanGroupsKeys.members(id), 'list'] as const,
  membersList: (id: string, params: SortedPaginationQueryV2, search: string) =>
    [...fanGroupsKeys.membersLists(id), params, search] as const,
  themes: () => [...fanGroupsKeys.all(), 'themes'] as const,
};

export const venuesKeys = {
  all: () => ['venues'] as const,
  lists: () => [...venuesKeys.all(), 'list'] as const,
  list: (params: VenuesQuery, bodyParams?: { query: string }) =>
    [...venuesKeys.lists(), params, bodyParams] as const,
  details: () => [...venuesKeys.all(), 'detail'] as const,
  detail: (id: string) => [...venuesKeys.details(), id] as const,
  configs: (venueId: string) =>
    [...venuesKeys.all(), venueId, 'configs'] as const,
  categories: (venueId: string) =>
    [...venuesKeys.all(), venueId, 'categories'] as const,
  configDetails: () => [...venuesKeys.all(), 'detail'] as const,
  configDetail: (id: string) => [...venuesKeys.configDetails(), id] as const,
  mutation: () => [...venuesKeys.all(), 'mutation'] as const,
};

export const eventsKeys = {
  all: () => ['events'] as const,
  lists: () => [...eventsKeys.all(), 'list'] as const,
  list: (params: EventsQuery) => [...eventsKeys.lists(), params] as const,

  adminList: (params: PaginationQueryV2, body: SearchEventRequestBody) =>
    [...eventsKeys.lists(), 'admin', params, body] as const,

  details: () => [...eventsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...eventsKeys.details(), id] as const,
  mutation: () => [...eventsKeys.all(), 'mutation'] as const,
  waitingLists: (eventId: string) =>
    [...eventsKeys.detail(eventId), 'waitingLists'] as const,

  waitingListsLists: (eventId: string) =>
    [...eventsKeys.waitingLists(eventId), 'list'] as const,
  waitingListsList: (eventId: string, params: EventsQuery) =>
    [...eventsKeys.waitingListsLists(eventId), params] as const,
};

export const waitingListsKeys = {
  all: () => ['waitingLists'] as const,
  allPerFanGroup: (fanGroupId: string) =>
    [...waitingListsKeys.all(), fanGroupId] as const,
  lists: (fanGroupId: string) =>
    [...waitingListsKeys.allPerFanGroup(fanGroupId), 'list'] as const,
  list: (fanGroupId: string, params: WaitingListQuery) =>
    [...waitingListsKeys.lists(fanGroupId), params] as const,
  filtered: (fanGroupId: string, params: WaitingListFiltersQuery) =>
    [...waitingListsKeys.lists(fanGroupId), params, 'filtered'] as const,
  details: () => [...waitingListsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...waitingListsKeys.details(), id] as const,
  badgesPerWL: (id: string) =>
    [...waitingListsKeys.detail(id), 'badges-state'] as const,
  additionalInfo: (id: string) =>
    [...waitingListsKeys.detail(id), id, 'additional-info'] as const,
  badges: (id: string) =>
    [...waitingListsKeys.details(), id, 'badges'] as const,
  codes: (id: string) => [...waitingListsKeys.details(), id, 'codes'] as const,
  codesLists: (id: string) => [...waitingListsKeys.codes(id), 'list'] as const,
  codesList: (id: string, params: CodesQuery) =>
    [...waitingListsKeys.codes(id), params] as const,
  scheduledActivationBadges: (id: string) =>
    [...waitingListsKeys.details(), id, 'scheduled-to-activation'] as const,
  scheduledArchivingBadges: (id: string) =>
    [...waitingListsKeys.details(), id, 'scheduled-to-archiving'] as const,
  detailPrice: (id: string) =>
    [...waitingListsKeys.detail(id), 'price'] as const,
  transaction: (id: string) =>
    [...waitingListsKeys.detail(id), 'transaction'] as const,
  positions: (id: string) => [...waitingListsKeys.detail(id), 'positions'],
  positionsDetails: (id: string) => [
    ...waitingListsKeys.positions(id),
    'details',
  ],
  positionsList: (id: string, params: PaginationQueryV2) => [
    ...waitingListsKeys.positions(id),
    params,
  ],
  seats: (id: string) => [...waitingListsKeys.detail(id), 'seats'],
  paymentInfo: (id: string) => [...waitingListsKeys.detail(id), 'payment-info'],
  seatsList: (id: string, params: PaginationQueryV2) => [
    ...waitingListsKeys.seats(id),
    params,
  ],
  seatsDetails: (id: string) => [...waitingListsKeys.seats(id), 'details'],
  // mutation: () => [...waitingListsKeys.all(), 'mutation'] as const,
};

export const joinedWaitingListsKeys = {
  all: () => ['joinedWaitingLists'] as const,
  allPerFanGroup: (fanGroupId: string) =>
    [...joinedWaitingListsKeys.all(), fanGroupId] as const,
  lists: () => [...joinedWaitingListsKeys.all(), 'list'] as const,
  list: (params: FanGroupsQuery) =>
    [...joinedWaitingListsKeys.lists(), params] as const,
  details: () => [...joinedWaitingListsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...joinedWaitingListsKeys.details(), id] as const,
};

export const waitingListsWithInvitationsKeys = {
  all: () => ['waitingListsWithInvitations'] as const,
  allPerFanGroup: (fanGroupId: string) =>
    [...waitingListsWithInvitationsKeys.all(), fanGroupId] as const,
  lists: () => [...waitingListsWithInvitationsKeys.all(), 'list'] as const,
  list: (params: WaitingListQuery) =>
    [...waitingListsWithInvitationsKeys.lists(), params] as const,
  details: () => [...waitingListsWithInvitationsKeys.all(), 'detail'] as const,
  detail: (id: string) =>
    [...waitingListsWithInvitationsKeys.details(), id] as const,
};

export const waitingListTicketsKeys = {
  all: () => ['waitingListTickets'] as const,
  lists: () => [...waitingListTicketsKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...waitingListTicketsKeys.lists(), params] as const,
  details: () => [...waitingListTicketsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...waitingListTicketsKeys.details(), id] as const,
};

export const waitingListInvitationsKeys = {
  all: () => ['waitingListInvitations'] as const,
  allPerWaitingList: (id: string) =>
    [...waitingListInvitationsKeys.all(), id] as const,
  lists: (id: string) =>
    [...waitingListInvitationsKeys.allPerWaitingList(id), 'list'] as const,
  list: <QueryType>(id: string, params: QueryType) =>
    [...waitingListInvitationsKeys.lists(id), params] as const,
  mutation: () => ['mutation'] as const,
};

export const waitingListConditionsKeys = {
  all: () => ['waitingListConditions'] as const,
  allPerWaitingList: (id: string) =>
    [...waitingListConditionsKeys.all(), id] as const,
};

export const waitingListInvitationsToApproveKeys = {
  all: () => ['waitingListInvitationsToApprove'] as const,
  allPerWaitingList: (id: string) =>
    [...waitingListInvitationsToApproveKeys.all(), id] as const,
  lists: (id: string) =>
    [
      ...waitingListInvitationsToApproveKeys.allPerWaitingList(id),
      'list',
    ] as const,
  list: (id: string, params: PaginationQueryV2) =>
    [...waitingListInvitationsToApproveKeys.lists(id), params] as const,
  details: () =>
    [...waitingListInvitationsToApproveKeys.all(), 'detail'] as const,
  detail: (id: string) =>
    [...waitingListInvitationsToApproveKeys.details(), id] as const,
  mutation: () =>
    [...waitingListInvitationsToApproveKeys.all(), 'mutation'] as const,
};

export const waitingListsApproverRequestsKeys = {
  all: () => ['waitingListsApproverRequests'] as const,
  lists: (waitingListId: string) =>
    [...waitingListsApproverRequestsKeys.all(), waitingListId, 'list'] as const,
  // details: () => [...waitingListsWithApprovalKeys.all(), 'detail'] as const,
  // detail: (id: string) =>
  //   [...waitingListsWithApprovalKeys.details(), id] as const,
};

export const waitingListsWithApprovalKeys = {
  all: () => ['waitingListsWithApproval'] as const,
  allPerFangroup: (id: string) =>
    [...waitingListsWithApprovalKeys.all(), id] as const,
  lists: (id: string) =>
    [...waitingListsWithApprovalKeys.allPerFangroup(id), 'list'] as const,
  list: (id: string, params: WaitingListWithApprovalQuery) =>
    [...waitingListsWithApprovalKeys.lists(id), params] as const,
  details: () => [...waitingListsWithApprovalKeys.all(), 'detail'] as const,
  detail: (id: string) =>
    [...waitingListsWithApprovalKeys.details(), id] as const,
};

export const surveyInstancesKeys = {
  all: () => ['surveysInstances'] as const,
  lists: () => [...surveyInstancesKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...surveyInstancesKeys.lists(), params] as const,

  details: () => [...surveyInstancesKeys.all(), 'detail'] as const,
  detail: (id: string) => [...surveyInstancesKeys.details(), id] as const,
  listPerWL: (params: Omit<SurveyQuery, 'fangroup_id'>) =>
    [...surveyInstancesKeys.lists(), params] as const,
  listPerFan: (fanGroupId: string) =>
    [...surveyInstancesKeys.lists(), fanGroupId] as const,
  answersBeforeJoin: (
    params: Omit<SurveyQuery, 'fangroup_id'>,
    instanceId: string
  ) => [...surveyInstancesKeys.listPerWL(params), instanceId],
  answersAfterEvent: (
    params: Omit<SurveyQuery, 'fangroup_id'>,
    instanceId: string
  ) => [...surveyInstancesKeys.listPerWL(params), instanceId],
  answersAfterSignIn: (fanGroupId: string, instanceId: string) => [
    ...surveyInstancesKeys.listPerFan(fanGroupId),
    instanceId,
  ],
  answersAtCheckout: (
    params: Omit<SurveyQuery, 'fangroup_id'>,
    instanceId: string
  ) => [...surveyInstancesKeys.listPerWL(params), instanceId],
  mutation: () => [...surveyInstancesKeys.all(), 'mutation'] as const,
};

export const surveyFansAnswers = {
  all: () => ['surveyFansAnswers'] as const,
  lists: () => [...surveyFansAnswers.all(), 'list'] as const,
  list: (params: FansAnswersQuery) =>
    [...surveyFansAnswers.lists(), params] as const,

  details: () => [...surveyFansAnswers.all(), 'detail'] as const,
  detail: (id: string) => [...surveyFansAnswers.details(), id] as const,
  listPerWL: (wlId: string, params: FansAnswersQuery) =>
    [...surveyFansAnswers.lists(), params, wlId] as const,
  listPerInstance: (
    surveyInstanceId: string,
    wlId: string,
    params: FansAnswersQuery
  ) =>
    [...surveyFansAnswers.listPerWL(wlId, params), surveyInstanceId] as const,
};

export const badgesCategoryKeys = {
  all: () => ['badgesCategory'] as const,
};

export const badgesKeys = {
  all: () => ['badges'] as const,
  lists: () => [...badgesKeys.all(), 'list'] as const,
  list: (params: BadgesQuery) => [...badgesKeys.lists(), params] as const,
  details: () => [...badgesKeys.all(), 'detail'] as const,
  detail: (id: string) => [...badgesKeys.details(), id] as const,
  mutation: () => [...badgesKeys.all(), 'mutation'] as const,
};

export const badgesEventsKeys = {
  all: () => ['badgesEvents'] as const,
  lists: () => [...badgesEventsKeys.all(), 'list'] as const,
  list: (params: PaginationQueryV2) =>
    [...badgesEventsKeys.lists(), params] as const,
  listPerUser: (fanGroupId: string, userId: string) =>
    [...badgesEventsKeys.lists(), fanGroupId, userId] as const,
};

export const fansKeys = {
  all: () => ['fans'] as const,
  lists: () => [...fansKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) => [...fansKeys.lists(), params] as const,
  listPerFG: (fanGroupId: string, params: PaginationQuery) =>
    [...fansKeys.list(params), fanGroupId] as const,
  details: () => [...fansKeys.all(), 'detail'] as const,
  detail: (id: string) => [...fansKeys.details(), id] as const,
  activitiesPerFan: (id: string, fanGroupId: string) =>
    [...fansKeys.detail(id), id, fanGroupId] as const,
  accessRequestsPerFan: (id: string) =>
    [...fansKeys.detail(id), 'access-requests'] as const,
  mutation: () => [...fansKeys.all(), 'mutation'] as const,
  customInfo: (id: string) => [...fansKeys.detail(id), 'custom-info'] as const,
};

export const surveysKeys = {
  all: () => ['surveys'] as const,
  lists: () => [...surveysKeys.all(), 'list'] as const,
  list: (params: SurveyPaginationQuery) =>
    [...surveysKeys.lists(), params] as const,
  mutation: () => [...surveysKeys.all(), 'mutation'] as const,
  details: () => [...surveysKeys.all(), 'detail'] as const,
  detail: (id: string) => [...surveysKeys.details(), id] as const,
};

export const questionsKeys = {
  all: () => ['questions'] as const,
  lists: () => [...questionsKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...questionsKeys.lists(), params] as const,
  mutation: () => [...questionsKeys.all(), 'mutation'] as const,
  details: () => [...questionsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...questionsKeys.details(), id] as const,
};

export const answerSemanticKeys = {
  all: () => ['answerSemantic'] as const,
  lists: () => [...answerSemanticKeys.all(), 'list'] as const,
  list: (params: SurveyPaginationQuery) =>
    [...answerSemanticKeys.lists(), params] as const,
};

export const interestsKeys = {
  all: () => ['interests'] as const,
  lists: () => [...interestsKeys.all(), 'list'] as const,
  categories: () => [...interestsKeys.all(), 'categories'] as const,
  mutation: () => [...interestsKeys.all(), 'mutation'] as const,
};

export const fanAttributesKeys = {
  all: () => ['fan-attributes'] as const,
  lists: () => [...fanAttributesKeys.all(), 'list'] as const,
  search: () => [...fanAttributesKeys.all(), 'search'] as const,
  searchKey: (key: string) => [...fanAttributesKeys.search(), key] as const,
  mutation: () => [...fanAttributesKeys.all(), 'mutation'] as const,
};

export const questionChoicesKeys = {
  all: () => ['choices'] as const,
  lists: () => [...questionChoicesKeys.all(), 'list'] as const,
  listPerQuestion: (questionId: string) =>
    [...questionChoicesKeys.lists(), questionId] as const,
};

export const languagesKeys = {
  all: () => ['languages'] as const,
  lists: () => [...languagesKeys.all(), 'list'] as const,
  list: (params: LanguagesQuery) => [...languagesKeys.lists(), params] as const,
};

export const groupsKeys = {
  all: () => ['groups'] as const,
  lists: () => [...groupsKeys.all(), 'list'] as const,
  list: (params: LanguagesQuery) => [...groupsKeys.lists(), params] as const,
  details: () => [...groupsKeys.all(), 'detail'] as const,
  guests: (id?: string) => [...groupsKeys.details(), id, 'guests'] as const,
  guestsLists: (id?: string) => [...groupsKeys.guests(id), 'list'] as const,
  guestsList: (id?: string, params?: GroupGuestsQuery) =>
    [...groupsKeys.guestsLists(id), params] as const,

  guestsMutation: (id?: string) =>
    [...groupsKeys.guests(id), 'mutation'] as const,
};

export const barCodesKeys = {
  all: () => ['bar-codes'] as const,
  lists: () => [...barCodesKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) => [...barCodesKeys.lists(), params] as const,
};

export const timeZonesKeys = {
  all: () => ['time-zones'] as const,
  lists: () => [...timeZonesKeys.all(), 'list'] as const,
  list: (params: TimeZonesQuery) => [...timeZonesKeys.lists(), params] as const,
};

export const mailcampaignKeys = {
  all: () => ['mailcampaign'] as const,
  lists: () => [...mailcampaignKeys.all(), 'list'] as const,
  list: (params: MailcampaignQuery) =>
    [...mailcampaignKeys.lists(), params] as const,
  details: () => [...mailcampaignKeys.all(), 'detail'] as const,
  detail: (id: string) =>
    [...mailcampaignKeys.details(), id] as const,

};

export const countriesKeys = {
  all: () => ['countries'] as const,
  lists: () => [...countriesKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...countriesKeys.lists(), params] as const,
};

export const currencyCodesKeys = {
  all: () => ['currency-codes'] as const,
  lists: () => [...currencyCodesKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...currencyCodesKeys.lists(), params] as const,
};

export const fanGroupOwnerKeys = {
  all: () => ['fanGroupOwner'] as const,
  lists: () => [...fanGroupOwnerKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...fanGroupOwnerKeys.lists(), params] as const,
  details: () => [...fanGroupOwnerKeys.all(), 'detail'] as const,
  detail: (id: string) => [...fanGroupOwnerKeys.details(), id] as const,
};

export const fanGroupCustomInfoKeys = {
  all: (id: string) => ['customInfo', id] as const,
  host: (id: string) => ['customInfo', id, 'host'] as const,
  lists: (id: string) => [...fanGroupCustomInfoKeys.all(id), 'list'] as const,
  list: (id: string, params: SortedPaginationQueryV2) =>
    [...fanGroupCustomInfoKeys.lists(id), params] as const,
  details: (id: string) =>
    [...fanGroupCustomInfoKeys.all(id), 'detail'] as const,
  detail: (id: string, customInfoId: string) =>
    [...fanGroupCustomInfoKeys.details(id), customInfoId] as const,
};

export const fanGroupOwnerWaitingListsKeys = {
  all: () => ['fgoWaitingLists'] as const,
  allPerFanGroup: (fanGroupId: string) =>
    [...fanGroupOwnerWaitingListsKeys.all(), fanGroupId] as const,
  lists: (fanGroupId: string) =>
    [
      ...fanGroupOwnerWaitingListsKeys.allPerFanGroup(fanGroupId),
      'list',
    ] as const,
  list: (fanGroupId: string, params: WaitingListQuery) =>
    [...fanGroupOwnerWaitingListsKeys.lists(fanGroupId), params] as const,

  filteredList: (
    fanGroupId: string,
    body: FGOWishListsValidator,
    params: WaitingListQuery
  ) =>
    [...fanGroupOwnerWaitingListsKeys.list(fanGroupId, params), body] as const,

  details: () => [...fanGroupOwnerWaitingListsKeys.all(), 'detail'] as const,
  detail: (id: string) =>
    [...fanGroupOwnerWaitingListsKeys.details(), id] as const,

  approvers: (id: string) =>
    [...fanGroupOwnerWaitingListsKeys.detail(id), 'approvers'] as const,
  approverRequests: (id: string) =>
    [...fanGroupOwnerWaitingListsKeys.detail(id), 'approverRequests'] as const,
  availableSeats: (id: string) =>
    [...fanGroupOwnerWaitingListsKeys.detail(id), 'availableSeats'] as const,
  availableSecondarySeats: (id: string) =>
    [
      ...fanGroupOwnerWaitingListsKeys.detail(id),
      'availableSecondarySeats',
    ] as const,
  expiredPositions: (id: string) =>
    [...fanGroupOwnerWaitingListsKeys.detail(id), 'expiredPositions'] as const,
};

export const fanGroupOwnerEventsKeys = {
  all: () => ['fgoEvents'] as const,
  lists: () => [...fanGroupOwnerEventsKeys.all(), 'list'] as const,
  list: (params: EventsQuery) =>
    [...fanGroupOwnerEventsKeys.lists(), params] as const,
  filteredList: (params: WaitingListQuery, body: SearchEventRequestBody) =>
    [...fanGroupOwnerEventsKeys.list(params), body] as const,
  details: () => [...fanGroupOwnerEventsKeys.all(), 'detail'] as const,
  detail: (id: string) => [...fanGroupOwnerEventsKeys.details(), id] as const,
};

export const fanGroupOwnerWaitingListNames = {
  all: () => ['fgoWaitingListNames'] as const,
  allPerFanGroup: (fanGroupId: string) =>
    [...fanGroupOwnerWaitingListNames.all(), fanGroupId] as const,
  lists: () => [...fanGroupOwnerWaitingListNames.all(), 'list'] as const,
  details: () => [...fanGroupOwnerWaitingListNames.all(), 'detail'] as const,
  detail: (id: string) =>
    [...fanGroupOwnerWaitingListNames.details(), id] as const,
};

export const authConfigKeys = {
  all: () => ['auth-configs'] as const,
  pim: (provider: string) =>
    [...currencyCodesKeys.all(), 'pim', provider] as const,
  pimCheckTransaction: (provider: string, query?: PimCheckTransactionQuery) =>
    [...authConfigKeys.pim(provider), 'check-transaction', query] as const,
  oauth: (provider: string) =>
    [...currencyCodesKeys.all(), 'oauth', provider] as const,
  saml: (provider: string) =>
    [...currencyCodesKeys.all(), 'saml', provider] as const,
};

export const guestKeys = {
  all: () => ['guest'] as const,
  fanGroup: (slug: string) => [...guestKeys.all(), 'fanGroup', slug] as const,
  waitingList: (waitingListId: string) =>
    [...guestKeys.all(), 'waitingList', waitingListId] as const,
  mutation: () => [...guestKeys.all(), 'mutation'] as const,
  rejectMutation: () => [...guestKeys.mutation(), 'reject'] as const,
  acceptMutation: () => [...guestKeys.mutation(), 'accept'] as const,
  nbrOfSeatsMutation: () =>
    [...guestKeys.mutation(), 'nbrOfSeatsMutation'] as const,
  seatsExportMutation: () => [...guestKeys.mutation(), 'seatsExport'] as const,
  parkingSeatsExportMutation: () =>
    [...guestKeys.mutation(), 'parkingSeatsExport'] as const,
  surveys: () => [...guestKeys.all(), 'surveys'] as const,
  surveysList: (waitingListId?: string) =>
    [...guestKeys.surveys(), 'list', waitingListId] as const,
  attendeeInfoMutation: () =>
    [...guestKeys.mutation(), 'attendeeInfo'] as const,
  surveyAnswerMutation: () =>
    [...guestKeys.mutation(), 'surveyAnswer'] as const,
};

export const ticketingKeys = {
  all: () => ['ticketing'] as const,
  systems: () => [...ticketingKeys.all(), 'systems'] as const,
  systemsLists: () => [...ticketingKeys.systems(), 'list'] as const,

  systemList: (params: PaginationQuery) =>
    [...ticketingKeys.systemsLists(), params] as const,

  details: () => [...ticketingKeys.systems(), 'detail'] as const,
  detail: (systemId: string) => [...ticketingKeys.details(), systemId] as const,

  //
  ticketPools: (systemId: string) =>
    [...ticketingKeys.detail(systemId), 'ticketPools'] as const,

  ticketPoolsLists: (systemId: string) =>
    [...ticketingKeys.ticketPools(systemId), 'list'] as const,

  ticketPoolList: (systemId: string, params: PaginationQuery) =>
    [...ticketingKeys.ticketPoolsLists(systemId), params] as const,
  ticketPoolDetail: (ticketPoolId: string) =>
    [...ticketingKeys.all(), 'ticketPools', ticketPoolId] as const,
};

export const paymentSystemsKeys = {
  all: () => ['payment-systems'] as const,
  lists: () => [...paymentSystemsKeys.all(), 'list'] as const,
  list: (params: PaginationQuery) =>
    [...paymentSystemsKeys.lists(), params] as const,
};
