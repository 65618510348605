import {
  MailcampaignEntity,
  MailcampaignRecipient,
} from '@seaters-app/constants';
import i18n from '../../../i18n/config';

export function recipientToLabel(
  recipient: MailcampaignEntity['recipient']
): string | undefined {
  switch (recipient) {
    case MailcampaignRecipient.FANGROUP:
      return i18n.t('mailcampaign_recipient_fangroup');
    case MailcampaignRecipient.WAITING_LIST:
      return i18n.t('mailcampaign_recipient_waitinglist');
    case MailcampaignRecipient.WAITING_LIST_NOT_DISTRIBUTED:
      return i18n.t('mailcampaign_recipient_waitinglist_not_distributed');
    case MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_NOT_ACCEPTED:
      return i18n.t(
        'mailcampaign_recipient_waitinglist_distributed_not_accepted'
      );
    case MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_ACCEPTED:
      return i18n.t('mailcampaign_recipient_waitinglist_distributed_accepted');
    default:
      return i18n.t('mailcampaign_recipient_fangroup');
  }
}
