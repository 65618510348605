export const defaultTemplate = (
  primaryColor: string,
  coverImageUrl: string
) => `<table style="background-color: #f9f9fb; width: 100%;" border="0">
      <tr>
        <td align="center">
          <table border="0" width="100%" style="max-width: 660px; width: 100%; background-color: #ffffff; border: 2px solid #eee; border-radius: 8px; overflow: hidden" cellpadding="0" cellspacing="0">
            <tr>
                <td width="100%">
                  <div class="tiny-editable" style="font-family: 'helvetica', sans-serif; font-size: 16px; line-height: 1.5;">
                  <img src="${coverImageUrl}" style="object-fit: cover; border-radius: 8px" width="100%" height="100%" alt="">
                  </div>
                </td>
              </tr>  
            <tr>
              <td style="padding: 16px 64px 0;" colspan="2">
                <div class="tiny-editable" style="font-family: 'helvetica', sans-serif; color: #243376;">
                  <p style="font-size: 20px; text-align: center;">Dear {{firstName}} {{lastName}},</p>
                  <p style="font-size: 20px; text-align: center;">Your event is coming soon 🚀</p>
                  <h2 style="text-align: center; font-size: 20px;">Claim your tickets by going to our website</h2>
                  <p style="text-align: center;"><a style="background-color: ${primaryColor}; padding: 12px 16px; color: rgb(255, 255, 255); border-radius: 4px; text-decoration: none; display: inline-block;" href="https://seaters.ai">Call to action</a></p>
                  <p style="text-align: center;">See you at the event!</p>
                </div>
              </td>
            </table>
        </td>
      </tr>
    </table>`;

export const defaultCoverImage =
  'https://seaters-public-images-6120fd4.s3.eu-west-1.amazonaws.com/public/704269ed-e9c2-4f37-80f7-1bdfaae3c32a';
