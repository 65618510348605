import {
  MailcampaignEntity,
  MailcampaignRecipient,
} from '@seaters-app/constants';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const getRecipientOptions = (): {
  value: string;
  label: string;
  selectWishList: boolean;
}[] => {
  const { t } = useTranslation();
  return [
    {
      value: MailcampaignRecipient.FANGROUP,
      label: t('mailcampaign_recipient_fangroup'),
      selectWishList: false,
    },
    {
      selectWishList: true,
      value: MailcampaignRecipient.WAITING_LIST,
      label: t('mailcampaign_recipient_waitinglist'),
    },
    {
      selectWishList: true,
      value: MailcampaignRecipient.WAITING_LIST_NOT_DISTRIBUTED,
      label: t('mailcampaign_recipient_waitinglist_not_distributed'),
    },
    {
      selectWishList: true,
      value: MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_NOT_ACCEPTED,
      label: t('mailcampaign_recipient_waitinglist_distributed_not_accepted'),
    },
    {
      selectWishList: true,
      value: MailcampaignRecipient.WAITING_LIST_DISTRIBUTED_ACCEPTED,
      label: t('mailcampaign_recipient_waitinglist_distributed_accepted'),
    },
  ];
};

export const getDetailStepInitialValues = (
  mailcampaignValues: MailcampaignEntity | null
) => {
  const mailCampaignFormInitialValues = useMemo(() => {
    if (!mailcampaignValues) {
      return {
        fangroup: '',
        name: '',
        wishlist: '',
        body: '',
        subject: '',
        replyTo: '',
        recipient: MailcampaignRecipient.FANGROUP,
      };
    } else {
      return {
        ...mailcampaignValues,
        wishlist: mailcampaignValues.wishlist?.id,
        fangroup: mailcampaignValues.fanGroup.id,
      };
    }
  }, [mailcampaignValues]);

  return mailCampaignFormInitialValues;
};
