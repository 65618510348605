import { uploadPublicImage } from "@seaters-app/data-access";

const uploadHandler = async (blobInfo: any, progress: any) => {
  debugger;
  const formData = new FormData();
  formData.append('file', blobInfo.blob(), blobInfo.filename());
  console.log('uploading image');
  console.log(progress)
  const response = await uploadPublicImage(formData, progress)
  return response.url
};

export const editorOptions = (colorPrimary: string) => ({
    plugins:
      'advcode advtemplate autolink emoticons image inlinecss link linkchecker lists markdown mergetags',
    toolbar:
      'undo redo | styles | bold italic forecolor | link image emoticons | align | mergetags inserttemplate | a11ycheck | code removeformat | markdown',
    toolbar_sticky: true,
    convert_urls: false,
    relative_urls: false,
    remove_script_host : false,
    paste_data_images: true,
    file_picker_types: 'image',
    menubar: false,
    height: 1020,
    editable_root: false,
    editable_class: 'tiny-editable',
    elementpath: false,
    visual: false,
    object_resizing: false,
    formats: {
      h1: { block: 'h1', styles: { fontSize: '24px', color: colorPrimary } },
      h2: { block: 'h2', styles: { fontSize: '20px' } },
      largetext: { block: 'p', styles: { fontSize: '20px' } },
      calltoaction: {
        selector: 'a',
        styles: {
          backgroundColor: colorPrimary,
          padding: '12px 16px',
          color: '#ffffff',
          borderRadius: '4px',
          textDecoration: 'none',
          display: 'inline-block',
        },
      },
    },
    images_upload_handler: uploadHandler,
    style_formats: [
      { title: 'Paragraph', format: 'p' },
      { title: 'Heading 1', format: 'h1' },
      { title: 'Heading 2', format: 'h2' },
      { title: 'Large text', format: 'largetext' },
      { title: 'Button styles' },
      { title: 'Call-to-action', format: 'calltoaction' },
    ],
    images_file_types: 'jpeg,jpg,png,gif',
    mergetags_list: [
      {
        title: 'Recipient',
        menu: [
          {
            value: 'firstName',
            title: 'Recipient First Name',
          },
          {
            value: 'lastName',
            title: 'Recipient Last Name',
          },
        ],
      },
    ],
    advtemplate_templates: [
      {
        title: 'CTA Button',
        content: `<p><a style="background-color: ${colorPrimary}; padding: 12px 16px; color: rgb(255, 255, 255); border-radius: 4px; text-decoration: none; display: inline-block; font-family:Arial;" href="https://seaters.com">Call To Action</a></p>`,
      },
    ],
    content_style: `
      body {
        background-color: #f9f9fb;
      }

      /* Edit area functional css */
      .tiny-editable {
        position: relative;
      }
      .tiny-editable:hover:not(:focus),
      .tiny-editable:focus {
        outline: 3px solid #b4d7ff;
        outline-offset: 4px;
      }

      /* Create an edit placeholder */
      .tiny-editable:empty::before,
      .tiny-editable:has(> br[data-mce-bogus]:first-child)::before {
        content: "Write here...";
        position: absolute;
        top: 0;
        left: 0;
        color: #999;
      }
    `,
  });