import {
  LOCAL_STORAGE_SLUG,
  LOCATION_STATES,
  LoginTypeEnum,
  routes,
} from '@seaters-app/constants';
import {
  getSlugFromUrl,
  useFetchLook,
  useFetchOauthAuthConfig,
} from '@seaters-app/data-access';
import { Button, Container } from '@seaters-app/ui';
import { Spin, Typography } from 'antd';
import './styles.css';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledText } from '@seaters-app/ui-shared';
import { useGetAuthOption } from '../SignIn/hooks/useGetAuthOption';
import { OAUTHLogin } from '../SignIn/components';
import { getModifiedUrl } from './helpers';
import { ApiErrorPage } from '../../error/ApiErrorPage';

const { Title } = Typography;

function Landing() {
  const slug = getSlugFromUrl();
  const { state: locationState } = useLocation();

  const { data, isLoading } = useFetchLook(slug ?? '');

  const { t, i18n } = useTranslation();
  const { language: lang } = i18n;
  const oauthOption = useGetAuthOption(LoginTypeEnum.OAUTH).login;

  const { data: oauthConfig } = useFetchOauthAuthConfig(
    oauthOption?.providerId ?? ''
  );

  const registrationIsHidden =
    !data?.registration.length ||
    data?.registration.find((type) => type === 'NONE');

  if (
    localStorage.getItem(LOCAL_STORAGE_SLUG) === slug &&
    oauthOption &&
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.includes(
      routes.waitingList
    ) &&
    oauthConfig &&
    oauthConfig.uri
  ) {
    const newURI = getModifiedUrl(oauthConfig.uri);
    window.open(newURI ?? '', '_self');
  }

  const showRedirectionLoader =
    locationState?.[LOCATION_STATES.REDIRECT_PATH]?.pathname.includes(
      routes.waitingList
    ) &&
    slug &&
    slug.includes('kpn') &&
    data?.login.length === 1 &&
    oauthOption &&
    !localStorage.getItem('embeddedOAuthCode');

  const isAdminApp = window.location.pathname.includes('admin');

  if (!slug && !isAdminApp) {
    return <ApiErrorPage />;
  }
  return (
    <Spin
      spinning={!!showRedirectionLoader || isLoading}
      tip={t('redirection_tip')}
    >
      <Container paddingTop={24} paddingBottom={24}>
        {!showRedirectionLoader ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: 30,
            }}
          >
            <div className="fanGroupDescription">
              <StyledText
                text={data?.description[lang] ?? data?.description.en ?? ''}
              />
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
                minWidth: 250,
                flexGrow: 1,
              }}
            >
              <Title level={5} style={{ margin: 0 }}>
                {t('onboarding-slide-action-getstarted')}
              </Title>
              {data?.login.length === 1 && oauthOption ? (
                <OAUTHLogin {...oauthOption} />
              ) : (
                <Link to={`../${routes.login}`} state={locationState}>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    data-testid="signInSubmitButton"
                  >
                    {t('button_text_login')}
                  </Button>
                </Link>
              )}
              {!registrationIsHidden ? (
                <Link to={`../${routes.signup}`}>
                  <Button
                    size="large"
                    htmlType="submit"
                    style={{ width: '100%' }}
                    data-testid="signInSubmitButton"
                  >
                    {t('button_text_register')}
                  </Button>
                </Link>
              ) : null}
            </div>
          </div>
        ) : null}
      </Container>
    </Spin>
  );
}

export default Landing;
