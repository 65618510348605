import { Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import { recipientToLabel } from '../../utils/recipientToLabel';
import { useFetchMailcampaign } from '@seaters-app/data-access';
import { useParams } from 'react-router-dom';
import { MailcampaignRequiredFormSchema } from '@seaters-app/constants';

export type StepType = {
  title: string;
  content: () => JSX.Element;
};

export default function MailCampaignDetail({
  edit = false,
  editDetail,
  editContent,
}: {
  edit?: boolean;
  editDetail?: () => void;
  editContent?: () => void;
}) {
  const { t } = useTranslation();
  const { mailcampaignId } = useParams();
  const { data: mailcampaignValues } = useFetchMailcampaign(mailcampaignId);
  const parsedMailcampaignValues =
    MailcampaignRequiredFormSchema.safeParse(mailcampaignValues);
  const errors = parsedMailcampaignValues.error?.format();

  return (
    <Space size="large" direction="vertical" style={{ width: '100%' }}>
      <Card
        title={t('mailcampaigns_details')}
        extra={
          edit ? (
            <Button
              onClick={() => {
                if (editDetail) editDetail();
              }}
            >
              {t('edit_button')} <EditOutlined />
            </Button>
          ) : null
        }
        style={{ width: '100%' }}
      >
        <p>
          <strong>{t('mailcampaign_subject')}: </strong>
          {mailcampaignValues?.subject || '... '}
          {!errors?.subject?._errors || (
            <i style={{ color: 'red' }}>({errors?.subject?._errors})</i>
          )}
        </p>
        <p>
          <strong>{t('mailcampaign_reply_to')}: </strong>
          {mailcampaignValues?.replyTo || '...'}
          {!errors?.replyTo?._errors || (
            <i style={{ color: 'red' }}>({errors?.replyTo?._errors})</i>
          )}
        </p>
        <p>
          <strong>{t('mailcampaign_recipient')}: </strong>
          {recipientToLabel(mailcampaignValues?.recipient) || ''}
          {mailcampaignValues?.recipient &&
          mailcampaignValues.recipient !== 'fangroup'
            ? ` (${mailcampaignValues?.wishlist?.name})`
            : ''}
          {!errors?.recipient?._errors || (
            <i style={{ color: 'red' }}>({errors?.recipient?._errors})</i>
          )}
        </p>
      </Card>
      <Card
        title={t('mailcampaigns_email_body')}
        extra={
          edit ? (
            <Button
              onClick={() => {
                if (editContent) editContent();
              }}
            >
              {t('edit_button')} <EditOutlined />
            </Button>
          ) : null
        }
        style={{ width: '100%' }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(mailcampaignValues?.body || '', {
              USE_PROFILES: { html: true },
            }),
          }}
        ></div>
      </Card>
      {/* TODO: Scheduling feature */}
      {/* <Card title="Send Options" style={{ width: '100%' }}>
          <Radio.Group
            style={{ width: '100%', minWidth: 300 }}
            value={sendMode}
            onChange={(e) => setSendMode(e.target.value)}
          >
            {sendOptions.map((option) => (
              <Card
                bordered
                key={option.value}
                style={{
                  marginBottom: 8,
                  borderColor:
                    sendMode === option.value ? colorPrimary : baseColor400,
                }}
              >
                <Radio value={option.value}>{option.label}</Radio>
              </Card>
            ))}
          </Radio.Group>
        </Card> */}
    </Space>
  );
}
