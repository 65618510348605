import {
  ListEntity,
  TimeZoneEntity,
  timeZonesKeys,
} from '@seaters-app/constants';
import { useQuery } from '@tanstack/react-query';
import { fetchTimeZones } from '../api';

export const useFetchTimeZones = () => {
  const params = {
    itemOffset: 0,
    maxPageSize: 700,
  };

  const { data: timeZones, isLoading } = useQuery<
    ListEntity<TimeZoneEntity>,
    Error
  >(timeZonesKeys.list(params), () => fetchTimeZones(params), {
    keepPreviousData: true,
  });

  const timeZonesOptions = timeZones?.items.map((timeZoneItem) => ({
    label: timeZoneItem.id,
    value: timeZoneItem.id,
  }));

  return {
    timeZonesOptions,
    isLoading,
  };
};
